/** @jsx jsx */
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Fragment, useEffect, useState } from "react"
import { animated, Spring } from "react-spring/web.cjs"
import { Flex, jsx } from "theme-ui"
import { getLanguageData } from "utils/helpers"
import LanguagePicker from "../language-picker"
import MenuItem from "./menu-item"

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { language, defaultLanguage } = useI18next()
  const languagePath = language === defaultLanguage ? "" : `${language}/`
  const {
    allDatoCmsMenuSetting: { nodes },
  } = useStaticQuery(graphql`
    query MobileMenuQuery {
      allDatoCmsMenuSetting {
        nodes {
          menuItems {
            id
            anchorSlug
            title
          }
          locale
        }
      }
    }
  `)
  const { menuItems } = getLanguageData(nodes, language)

  const toggleMenu = () => setIsOpen(!isOpen)

  useEffect(() => {
    const root = document.getElementsByTagName("html")[0]
    if (isOpen) {
      root.classList.add("menu-open")
    } else if (!isOpen) {
      root.classList.remove("menu-open")
    }
  }, [isOpen])

  return (
    <Fragment>
      <Flex sx={{ height: "100%" }}>
        <button
          className={classNames([
            "hamburger",
            "hamburger--squeeze",
            { "is-active": isOpen },
          ])}
          title="Menu button"
          type="button"
          onClick={toggleMenu}
          sx={{
            zIndex: 8911,
          }}
        >
          <span className={"hamburger-box"}>
            <span className={"hamburger-inner"} />
          </span>
        </button>
      </Flex>
      <Flex
        sx={{
          flexDirection: "column",
          transition: "ease-in-out 100ms opacity",
          alignItems: "center",
          justifyContent: "flex-start",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "darkGreen",
          zIndex: 8910,
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? "all" : "none",
        }}
      >
        <Flex sx={{ flexDirection: "column", mt: 10 }}>
          {menuItems.map(({ id, anchorSlug, title }, index) => (
            <Spring
              key={id}
              config={{
                duration: isOpen ? 100 : 0,
              }}
              delay={isOpen ? 200 + index * 50 : 0}
              to={{
                opacity: isOpen ? 1 : 0,
                transform: isOpen
                  ? "translate3d(0,0,0)"
                  : "translate3d(15px,0,0)",
              }}
            >
              {({ opacity, transform }) => (
                <animated.div
                  onClick={toggleMenu}
                  sx={{ width: "100%" }}
                  style={{
                    opacity: opacity as any,
                    transform: transform,
                  }}
                >
                  <MenuItem to={`/${languagePath}#${anchorSlug}`}>
                    {title}
                  </MenuItem>
                </animated.div>
              )}
            </Spring>
          ))}
          <Spring
            config={{
              duration: isOpen ? 500 : 0,
            }}
            delay={isOpen ? 200 + menuItems.length * 50 : 0}
            to={{
              opacity: isOpen ? 1 : 0,
            }}
          >
            {({ opacity }) => (
              <animated.div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: 5,
                }}
                style={{
                  opacity: opacity as any,
                }}
              >
                <LanguagePicker />
              </animated.div>
            )}
          </Spring>
        </Flex>
      </Flex>
    </Fragment>
  )
}

export default MobileMenu
