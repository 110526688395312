/** @jsx jsx */
import { forwardRef, Fragment, ReactNode } from "react"
import { Box, Flex, FlexProps, jsx } from "theme-ui"
import Cookies from "./cookies"
import Footer from "./footer"
import Header from "./header"

interface Props {
  children?: ReactNode
}

export const FullContainer = forwardRef<any, FlexProps>(
  ({ children, ...props }, ref) => (
    <div
      ref={ref}
      sx={{
        width: "100%",
        px: [5, 5, 3, 5],
      }}
      {...props}
    >
      {children}
    </div>
  )
)

export const Row = forwardRef<any, FlexProps>(({ children, ...props }, ref) => (
  <Flex
    ref={ref}
    sx={{
      flexWrap: "wrap",
      mx: [-5, -5, -3, -5],
    }}
    {...props}
  >
    {children}
  </Flex>
))

export const Column = forwardRef<any, FlexProps>(
  ({ children, ...props }, ref) => (
    <Box
      ref={ref}
      sx={{
        boxSizing: "border-box",
        px: [5, 5, 3, 5],
        flex: [
          "1 1 100%",
          "1 1 100%",
          "1 1 50%",
          "1 1 16.66666667%",
          "1 1 12.5%",
        ],
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
)

const Layout = ({ children }: Props) => (
  <Fragment>
    <Header />
    {children}
    <Cookies />
    <Footer />
  </Fragment>
)

export default Layout
