/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { useCallback, useEffect, useState } from "react"
import { Flex, jsx } from "theme-ui"
import { getLanguageData } from "utils/helpers"
import { Media } from "utils/media"
import LanguagePicker from "../language-picker"
import MenuItem from "./menu-item"

const DesktopMenu = () => {
  const { language, defaultLanguage } = useI18next()
  const languagePath = language === defaultLanguage ? "" : `${language}/`
  const [activeElem, setActiveElem] = useState<any>()
  const {
    allDatoCmsMenuSetting: { nodes },
  } = useStaticQuery(graphql`
    query DesktopMenuQuery {
      allDatoCmsMenuSetting {
        nodes {
          menuItems {
            id
            anchorSlug
            title
          }
          locale
        }
      }
    }
  `)
  const { menuItems } = getLanguageData(nodes, language)
  // const sections = menuItems.map(i => i.anchorSlug)

  const onScroll = useCallback(() => {
    Array.from(document.querySelectorAll("section")).forEach(section => {
      if (
        section.getBoundingClientRect().top < 100 &&
        section.getBoundingClientRect().bottom > 100 &&
        section.id !== activeElem
        // && sections.includes(section.id)
      ) {
        setActiveElem(section.id)
      }
    })
  }, [activeElem])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [onScroll])

  return (
    <Flex
      sx={{
        minHeight: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {menuItems.map(({ id, anchorSlug, title }) => (
        <MenuItem
          key={id}
          to={`/${languagePath}#${anchorSlug}`}
          active={activeElem === anchorSlug}
        >
          {title}
        </MenuItem>
      ))}
      <Media lessThan={"lg"}>
        <LanguagePicker />
      </Media>
    </Flex>
  )
}

export default DesktopMenu
