/** @jsx jsx */
import DesktopMenu from "components/menus/desktop-menu"
import LanguagePicker from "components/menus/language-picker"
import MobileMenu from "components/menus/mobile-menu"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Container, jsx } from "theme-ui"
import { getLanguageData } from "utils/helpers"
import { Media } from "utils/media"
import { Row } from ".."

const Header = () => {
  const { language, defaultLanguage } = useI18next()
  const languagePath = language === defaultLanguage ? "" : `${language}/`
  const {
    allDatoCmsGeneralInfo: { nodes },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      allDatoCmsGeneralInfo {
        nodes {
          companyLogo {
            url
            alt
            title
            width
            height
          }
          locale
        }
      }
    }
  `)
  const { id, companyLogo } = getLanguageData(nodes, language)

  return (
    <header
      sx={{
        backgroundColor: "darkGreen",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 9999,
      }}
    >
      <Container>
        <Row sx={{ justifyContent: "space-between", mx: "0 !important" }}>
          <Link
            to={`/${languagePath}`}
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              key={id}
              alt={"Speedies logo"}
              title={companyLogo.title}
              src={companyLogo.url}
              width={companyLogo.width}
              height={companyLogo.height}
              sx={{
                p: 0,
                my: 2,
                maxWidth: "135px",
                maxHeight: "43px",
                zIndex: 8911,
              }}
            />
          </Link>
          <Media greaterThanOrEqual={"md"}>
            <DesktopMenu />
          </Media>
          <Media lessThan={"md"}>
            <MobileMenu />
          </Media>
        </Row>
      </Container>
      <Media greaterThan={"md"}>
        <LanguagePicker />
      </Media>
    </header>
  )
}

export default Header
