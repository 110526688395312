/** @jsx jsx */
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Flex, jsx } from "theme-ui"

const MenuItem = props => (
  <Flex
    sx={{
      position: "relative",
      minHeight: "100%",
      height: "100%",
      ml: [0, 0, 0, 3, 4],
    }}
  >
    <AnchorLink
      {...props}
      stripHash
      activeClassName="active"
      sx={{
        variant: "links.nav",
        fontSize: [0, 0, 0, 2, 3],
        textAlign: "center",
        alignSelf: "center",
        ":hover": {
          color: "primary",
        },
      }}
    />
    {props.active && (
      <span
        sx={{
          width: "100%",
          height: "5px",
          backgroundColor: "primary",
          position: "absolute",
          left: 0,
          bottom: 0,
        }}
      />
    )}
  </Flex>
)

export default MenuItem
