/** @jsx jsx */
import { jsx, Flex, Container, SxStyleProp } from "theme-ui"
import { useI18next, Link } from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import { getLanguageData } from "utils/helpers"
import { Row } from ".."
import { Media } from "utils/media"

const LinkStyle: SxStyleProp = {
  my: 1,
  a: {
    textTransform: "uppercase",
    color: "white",
    fontSize: "1.125rem",
    textDecoration: "none",
    ":hover": {
      color: "primary",
    },
    ":visited": {
      color: "white",
      ":hover": {
        color: "primary",
      },
    },
  },
}

const ColumnStyle: SxStyleProp = {
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  px: [5, 5, 3, 5],
  my: 4,
  flex: ["0 0 100%", "0 0 100%", "0 0 100%", "0 0 33.3333%"],
}

const Footer = () => {
  const { language } = useI18next()
  const {
    allDatoCmsGeneralInfo: { nodes },
  } = useStaticQuery(graphql`
    query FooterQuery {
      allDatoCmsGeneralInfo {
        nodes {
          companyName
          companyLogo {
            url
            alt
            title
            width
            height
          }
          locale
          email
          streetName
          zipCode
          city
        }
      }
    }
  `)
  const {
    id,
    companyLogo,
    companyName,
    email,
    streetName,
    zipCode,
    city,
  } = getLanguageData(nodes, language)

  return (
    <Flex
      as={"footer"}
      sx={{
        backgroundColor: "darkGreen",
        alignItems: "center",
        flexDirection: "column",
        py: [6, 7, 7, 8],
        color: "white",
      }}
    >
      <Container>
        <Row>
          <Flex
            sx={{
              ...ColumnStyle,
              alignItems: "flex-start",
            }}
          >
            <div sx={LinkStyle}>
              <Link to={"/corporate-guidelines"}>Corporate Guidelines</Link>
            </div>
            <div sx={LinkStyle}>
              <Link to={"/privacy"}>Privacy Policy</Link>
            </div>
          </Flex>
          <Media greaterThanOrEqual={"md"} sx={ColumnStyle}>
            <img
              key={id}
              alt={"Speedies logo"}
              title={companyLogo.title}
              src={companyLogo.url}
              width={companyLogo.width}
              height={companyLogo.height}
              sx={{
                p: 0,
                width: "210px",
                height: "67px",
                mb: 0,
                zIndex: 1011,
              }}
            />
          </Media>
          <Flex
            sx={{
              ...ColumnStyle,
              textAlign: ["left", "left", "left", "right"],
              alignItems: [
                "flex-start",
                "flex-start",
                "flex-start",
                "flex-end",
              ],
            }}
          >
            <p sx={{ mb: 0, fontWeight: "bold" }}>{companyName}</p>
            <p sx={{ mb: 0 }}>{`${streetName} | ${zipCode} | ${city}`}</p>
            <p sx={{ mb: 0 }}>
              <a
                href={`mailto:${email}`}
                target={"_blank"}
                rel={"noreferrer"}
                sx={{ color: "white", textDecoration: "none" }}
              >
                {email}
              </a>
            </p>
          </Flex>
          <Media
            lessThan={"md"}
            sx={{
              ...ColumnStyle,
              mt: [7, 7, 7, 3],
              textAlign: ["center", "left", "left", "right"],
              alignItems: ["center", "center", "center", "flex-end"],
            }}
          >
            <img
              key={id}
              alt={"Speedies logo"}
              title={companyLogo.title}
              src={companyLogo.url}
              width={companyLogo.width}
              height={companyLogo.height}
              sx={{
                p: 0,
                width: "210px",
                height: "67px",
                mb: 0,
                zIndex: 1011,
              }}
            />
          </Media>
        </Row>
      </Container>
    </Flex>
  )
}

export default Footer
