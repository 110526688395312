export const getLanguageData = (nodes: any, language: string) => {
  const filtered = nodes.filter(n => n.locale === language)

  return filtered.length === 1 ? filtered[0] : filtered
}

interface Bounds {
  left: number
  top: number
  width: number
  height: number
}

export const encode = (data: any) => {
  const formData = new FormData()

  Object.keys(data).map(key => {
    formData.append(key, data[key])

    return key
  })

  return formData
}

export const getCookie = cname => {
  const name = cname + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }

  return undefined
}

export const setCookie = (cname, cvalue, exdays = 7) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

export function throttle(
  callback: any,
  wait: number,
  immediate: boolean = false
) {
  let timeout = null
  let initialCall = true

  return function () {
    const callNow = immediate && initialCall
    const args = arguments
    const next = () => {
      callback.apply(this, args)
      timeout = null
    }

    if (callNow) {
      initialCall = false
      next()
    }

    if (!timeout) {
      timeout = setTimeout(next, wait)
    }
  }
}
