/** @jsx jsx */
import { OutlineButton } from "components/buttons"
import { graphql, useStaticQuery } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { useEffect, useState } from "react"
import ReactGA from "react-ga"
import ReactMarkdown from "react-markdown"
import { animated, useSpring } from "react-spring/web.cjs"
import { Box, Container, Flex, jsx } from "theme-ui"
import { getCookie, getLanguageData, setCookie } from "utils/helpers"

const Cookies = () => {
  const { language } = useI18next()
  const { t } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)
  const style = useSpring({
    transform: visible ? "translateY(0%)" : "translateY(100%)",
  })

  const {
    allDatoCmsGeneralInfo: { nodes },
    sitePlugin,
  } = useStaticQuery(graphql`
    query CookiesQuery {
      allDatoCmsGeneralInfo {
        nodes {
          cookiesDescription
          locale
        }
      }
      sitePlugin(
        pluginOptions: { googleAnalytics: { trackingId: { ne: null } } }
      ) {
        pluginOptions {
          googleAnalytics {
            trackingId
          }
        }
      }
    }
  `)

  const { cookiesDescription } = getLanguageData(nodes, language)

  const acceptCookie = () => {
    setCookie("gdpr-google-analytics", true)
    ReactGA.initialize(sitePlugin.pluginOptions.googleAnalytics.trackingId)
    ReactGA.pageview(window.location.pathname)
    setVisible(false)
  }

  useEffect(() => {
    if (!getCookie("gdpr-google-analytics")) {
      setTimeout(() => setVisible(true), 2000)
    }
  }, [])

  return (
    <animated.div
      style={style}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 9200,
        boxShadow: "overlay",
      }}
    >
      <Flex
        sx={{
          width: "100%",
          backgroundColor: "light",
          color: "text",
          py: 3,
          alignItems: "center",
        }}
      >
        <Container>
          <Flex sx={{ alignItems: "center", mx: -2 }}>
            <Box sx={{ flex: [1, 2, 4, 4], px: 2 }}>
              <ReactMarkdown
                sx={{
                  "*": {
                    fontSize: [0, 0, 1],
                    m: 0,
                  },
                }}
              >
                {cookiesDescription}
              </ReactMarkdown>
            </Box>
            <Box sx={{ flex: [1, 1, 2, 1], px: 2 }}>
              <OutlineButton
                sx={{ ml: "auto" }}
                buttonStyle="dark"
                onClick={acceptCookie}
              >
                {t("Accept cookies")}
              </OutlineButton>
            </Box>
          </Flex>
        </Container>
      </Flex>
    </animated.div>
  )
}

export default Cookies
