/** @jsx jsx */
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { jsx } from "theme-ui"

const MenuItem = props => (
  <AnchorLink
    {...props}
    activeClassName="active"
    sx={{
      display: "block",
      variant: "links.nav",
      fontSize: [6, 5, 5],
      textAlign: "center",
      mb: [4, 6, 7],
      ":hover": {
        color: "primary",
      },
    }}
  />
)

export default MenuItem
