/** @jsx jsx */
import { jsx, Button, ButtonProps, Flex } from "theme-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useState, ReactNode } from "react"
import { Arrow, DownloadIcon } from "styles/svg"
import { useSpring, animated, config } from "react-spring/web.cjs"

interface Props {
  showArrow?: boolean
  onClick: any
}

const ShowMore = ({
  onClick,
  showArrow = false,
  ...props
}: Props & ButtonProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const { transform } = useSpring({
    transform: open ? "scaleY(-1)" : "scaleY(1)",
    config: config.slow,
  })

  return (
    <Flex
      onClick={e => {
        setOpen(!open)
        onClick(e)
      }}
      sx={{
        flexDirection: "column",
        alignItems: showArrow ? "center" : "start",
      }}
    >
      <Button
        sx={{ variant: "buttons.showMore", "&:focus": { outline: "none" } }}
        {...props}
      >
        {open ? t("Show less") : t("Show more")}
        <span
          sx={{
            position: "absolute",
            width: "100%",
            height: "7px",
            backgroundColor: "primary",
            bottom: "-15px",
            left: 0,
          }}
        />
      </Button>
      {!!showArrow && (
        <animated.div style={{ transform }}>
          <Arrow />
        </animated.div>
      )}
    </Flex>
  )
}

interface DownloadProps {
  children?: ReactNode
}
const DownloadButton = ({
  children,
  ...props
}: DownloadProps & ButtonProps) => (
  <OutlineButton
    {...props}
    sx={{
      pl: 3,
      pr: "60px",
      "&:hover .download-icon": {
        fill: "white",
      },
    }}
  >
    {children}
    <div
      sx={{
        maxHeight: "100%",
        height: "100%",
        width: "46px",
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      <DownloadIcon
        className={"download-icon"}
        sx={{ height: "100%", width: "100%", fill: "text" }}
      />
    </div>
  </OutlineButton>
)

interface OutlineProps {
  buttonStyle?: "dark" | "light"
}
const OutlineButton = ({
  buttonStyle = "dark",
  ...props
}: OutlineProps & ButtonProps) => {
  return (
    <Button
      sx={{
        display: "block",
        backgroundColor: "transparent",
        color: /dark/.test(buttonStyle) ? "text" : "white",
        overflow: "hidden",
        textTransform: "uppercase",
        border: "1px solid black",
        borderColor: /dark/.test(buttonStyle) ? "text" : "white",
        fontSize: 1,
        py: 2,
        px: 3,
        position: "relative",
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
          backgroundColor: /dark/.test(buttonStyle) ? "text" : "white",
          color: /dark/.test(buttonStyle) ? "white" : "text",
        },
      }}
      {...props}
    />
  )
}

const SendButton = (props: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      {...props}
      sx={{
        px: 4,
        color: "#003a1a",
        cursor: "pointer",
      }}
    >
      {t("Send")}
    </Button>
  )
}

export { ShowMore, DownloadButton, SendButton, OutlineButton }
