import { graphql, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { HelmetDatoCms } from "gatsby-source-datocms"
import React, { ReactNode } from "react"
import { getLanguageData } from "utils/helpers"
import config from "../../gatsby-config"

interface Props {
  datoMetaTags?: any
  children?: ReactNode
}

const SEO = ({ datoMetaTags, children }: Props) => {
  const { language } = useI18next()
  const {
    allDatoCmsSite: { nodes },
  } = useStaticQuery(graphql`
    query SeoQuery {
      allDatoCmsSite {
        nodes {
          locale
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
      }
    }
  `)
  const { faviconMetaTags } = getLanguageData(nodes, language)

  return (
    <HelmetDatoCms seo={datoMetaTags} favicon={faviconMetaTags}>
      <html lang={language} />
      <link
        rel="preload"
        href="https://use.typekit.net/iuv0glv.css"
        as="style"
      />
      <link rel="stylesheet" href="https://use.typekit.net/iuv0glv.css" />
      <meta
        name="viewport"
        content="width=device-width,  user-scalable=yes,initial-scale=1.0"
      />
      <meta
        key="og:url"
        property="og:url"
        content={`${config.siteMetadata.siteUrl}/${
          /en/.test(language) ? "" : language
        }`}
      />
      {children}
    </HelmetDatoCms>
  )
}

export default SEO
