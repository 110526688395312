export const Shape = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 207.8 196"
    {...props}
  >
    <path
      d="M182.4,123.6l-6.1-20.3c-0.5-1.5-1.3-2.8-2.4-3.9c-0.2-0.2-0.4-0.4-0.7-0.6c-0.5-0.4-1-0.7-1.6-1l-28.4-15.2
c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.4-0.3l-0.2-0.2c-0.3-0.3-0.5-0.6-0.7-1l-0.1-0.2c-0.1-0.2-0.1-0.3-0.2-0.5l-2.1-34.9
c0-0.3-0.1-0.7-0.1-1c-0.3-1.6-0.9-3.1-1.9-4.4c-0.2-0.3-0.4-0.5-0.6-0.7c-0.2-0.2-0.5-0.5-0.7-0.7l-13.4-11.1
c-0.4-0.4-0.9-0.7-1.4-1l-0.1-0.1c-0.2-0.1-0.3-0.2-0.5-0.3l-0.7-0.3c-0.2-0.1-0.5-0.2-0.8-0.2c-0.5-0.1-1.1-0.2-1.6-0.3
c-0.3,0-0.5,0-0.8,0L86.6,25h-0.2c-0.8,0-1.5,0.1-2.3,0.3c-0.5,0.1-1,0.3-1.4,0.5c-0.8,0.4-1.6,0.9-2.3,1.5L65.1,40.6
c-0.2,0.2-0.4,0.4-0.6,0.6l-0.2,0.2c-0.1,0.2-0.3,0.3-0.4,0.5l-0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.5l-0.4,0.8c-0.1,0.2-0.2,0.4-0.3,0.6
s-0.1,0.3-0.2,0.4s-0.1,0.2-0.1,0.4l-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3l-0.2,1.1c0,0.4-0.1,0.7-0.1,1.1l-0.2,34c0,1.3-0.7,2.5-1.7,3.2
l-28.1,19c-0.3,0.2-0.6,0.4-0.9,0.7l-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3l-0.5,0.5c-0.2,0.2-0.3,0.3-0.4,0.5l-0.2,0.3
c-0.1,0.2-0.2,0.4-0.3,0.5l-0.4,0.8c-0.1,0.2-0.1,0.3-0.2,0.6l-0.1,0.3l-0.2,0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.4
l-2.6,20.6c-0.1,1-0.1,2,0.1,3c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.3,0.2,0.5,0.3,0.8c0.2,0.5,0.5,1,0.8,1.5l16.6,24.8
c0.2,0.2,0.3,0.4,0.5,0.6s0.3,0.4,0.5,0.6c0.4,0.4,0.8,0.8,1.2,1.1c0.4,0.3,0.9,0.6,1.4,0.8c0.5,0.2,1,0.4,1.5,0.6l16.7,5.1
c0.3,0.1,0.6,0.2,1,0.2c0.6,0.1,1.3,0.2,1.9,0.2l0,0c1.3,0,2.5-0.3,3.7-0.8c0.3-0.1,0.6-0.3,0.9-0.4l29.6-17.3
c0.1-0.1,0.2-0.1,0.3-0.2l0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.1c0.2-0.1,0.5-0.1,0.8-0.1c0.3,0,0.5,0,0.8,0.1l0.2,0.1
c0.3,0.1,0.7,0.2,1,0.4l28.4,15.2c0.5,0.3,1.1,0.5,1.7,0.7c0.9,0.3,1.9,0.4,2.9,0.4c0.6,0,1.1-0.1,1.6-0.2c0.3-0.1,0.6-0.1,0.9-0.2
l20.2-6.1c1.8-0.5,3.4-1.6,4.6-3c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.3,0.3-0.5,0.5-0.8l14.3-26.7c0.1-0.3,0.3-0.6,0.4-0.8
c0.2-0.6,0.4-1.2,0.5-1.8c0.1-0.3,0.1-0.6,0.1-0.9c0-0.5,0-1,0-1.5C182.7,125.1,182.6,124.4,182.4,123.6L182.4,123.6z M67.9,45.7
l0.5-0.6c0.1-0.1,0.2-0.2,0.3-0.3L84,31.5c0.3-0.2,0.6-0.5,0.9-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.3-0.1,0.7-0.1,1-0.1h0.1l29.8,0.1h0.3
c0.2,0,0.4,0.1,0.7,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.3,0.2l0.3,0.2l0.3,0.2l13.5,11.1l0.3,0.3l0.3,0.3c0.2,0.2,0.3,0.4,0.4,0.7
c0.2,0.4,0.3,0.8,0.4,1.2c0,0.1,0,0.3,0,0.4l1.9,34.2c0,0.6,0.1,1.2,0.3,1.8c0.1,0.4,0.2,0.8,0.4,1.1c0.1,0.2,0.2,0.4,0.2,0.6
c0.2,0.3,0.4,0.7,0.6,1c0.3,0.5,0.7,1,1.1,1.4c0.1,0.2,0.3,0.3,0.4,0.4c0.6,0.6,1.3,1,2,1.4l28.4,15.2c0.2,0.1,0.5,0.3,0.6,0.4
c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.3,0.4,0.6,0.5,1l6.1,20.2c0.1,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.4,0,0.6
l-0.1,0.4c-0.1,0.3-0.1,0.5-0.2,0.7c0,0.1-0.1,0.2-0.2,0.3L162.5,155c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
c-0.3,0.4-0.7,0.7-1.1,0.9c-0.3,0.2-0.5,0.3-0.8,0.4l-20.3,6.1l-0.4,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.4,0-0.8-0.1-1.1-0.2
c-0.2-0.1-0.5-0.2-0.7-0.3l-28.4-15.2c-0.4-0.2-0.7-0.4-1.1-0.5c-0.4-0.1-0.8-0.3-1.2-0.4l-0.6-0.1c-1.2-0.2-2.4-0.2-3.6,0.1
c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.3-0.8,0.4L69.8,165l-0.4,0.2c-0.5,0.2-1,0.4-1.6,0.4
c-0.3,0-0.5,0-0.8-0.1l-0.4-0.1l-16.7-5.1l-0.3-0.1l-0.3-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.3-0.5-0.4L48,159l-0.2-0.3
L31.2,134c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.6-0.2-1.2-0.2-1.8l2.6-20.1c0-0.2,0.1-0.3,0.1-0.5l0.3-0.7l-1-0.5
l1.1,0.3l0.2-0.4c0-0.1,0.1-0.1,0.2-0.3l0.4-0.4c0.1-0.1,0.2-0.2,0.4-0.3l28.2-19c2.6-1.7,4.1-4.6,4.2-7.7l0.2-34
c0-0.2,0-0.4,0.1-0.6l0.4-1.2"
    />
    <path
      d="M206.3,116.4l-6.1-20.2c-2.6-8.8-8.6-16.1-16.7-20.4l-17.9-9.6l-1.3-22.4c-0.6-9.6-5.1-18.5-12.4-24.6L138.4,8
c-6.1-5.1-13.8-7.8-21.8-7.9L86.7,0h-0.9c-8,0.2-15.7,3.1-21.8,8.4L48.8,21.6c-7.5,6.5-11.8,15.9-11.9,25.8l-0.2,22.9l-19,12.8
C9.5,88.7,4.1,97.5,2.8,107.3l-2.5,20.1c-1.1,8.3,0.9,16.7,5.6,23.6l16.6,24.8c4.4,6.6,11,11.4,18.6,13.7l16.7,5.1
c9.2,2.8,19.1,1.6,27.4-3.3l19.4-11.4l18,9.6c8.1,4.3,17.5,5.2,26.2,2.6l20.3-6.1c8.8-2.6,16.1-8.6,20.4-16.7l14.3-26.7
C208,134.6,209,125.1,206.3,116.4z M125.1,184.7l-20.7-11.1l-22,12.9c-7,4-15.3,5-23,2.7l-16.7-5c-6.4-1.9-12-6.1-15.7-11.6
l-16.6-24.8c-3.9-5.8-5.6-12.8-4.7-19.8L8.3,108c1.1-8.3,5.6-15.6,12.5-20.3l21.4-14.4l0.2-25.8c0.1-8.3,3.7-16.2,10-21.7l15.3-13.3
C72.7,8.1,79.2,5.6,86,5.5l30.6,0.1c6.7,0,13.2,2.4,18.3,6.6l13.4,11.1c6.2,5.1,10,12.6,10.5,20.7l1.4,25.5l20.6,11.1
c6.8,3.6,11.8,9.8,14.1,17.1l6.1,20.3c2.2,7.3,1.4,15.2-2.2,22l-14.3,26.7c-3.6,6.8-9.8,11.8-17.1,14l-20.2,6.1
C139.8,189.1,131.8,188.3,125.1,184.7L125.1,184.7z"
    />
    <path
      d="M194.1,120.1L188,99.8c-1.7-5.5-5.5-10.1-10.5-12.8l-24.2-13l-1.7-29.5c-0.3-6-3.2-11.7-7.9-15.5l-13.4-11.1
c-3.9-3.2-8.7-5-13.7-5l-29.8-0.1h-0.6c-5.1,0.1-9.9,2-13.7,5.3L57.1,31.3c-4.7,4.1-7.5,10-7.5,16.2l-0.2,29.6L24.8,93.7
c-5.2,3.5-8.6,9.1-9.4,15.3l-2.6,20c-0.7,5.2,0.6,10.5,3.5,14.8L33,168.6c2.8,4.2,6.9,7.2,11.7,8.7l16.7,5c5.8,1.8,12,1,17.3-2
l25.5-15l24.2,13c5.1,2.7,11,3.3,16.5,1.7l20.2-6.1c5.5-1.6,10.1-5.4,12.9-10.5l14.3-26.7C195.2,131.6,195.8,125.6,194.1,120.1
L194.1,120.1z M187.6,134l-14.3,26.8c-2,3.8-5.5,6.6-9.6,7.9l-20.2,6.1c-4.1,1.2-8.6,0.8-12.4-1.2l-27-14.4L76,175.6
c-3.9,2.3-8.6,2.8-12.9,1.5l-16.7-5.1c-3.6-1.1-6.7-3.4-8.8-6.5L21,140.8c-2.2-3.3-3.1-7.2-2.6-11.1l2.6-20.1
c0.6-4.6,3.1-8.8,7-11.4l26.9-18.2l0.2-32.5c0-4.7,2.1-9.1,5.6-12.1L76,22.2c2.9-2.5,6.5-3.9,10.3-4l30.2,0.1
c3.7,0,7.4,1.3,10.3,3.7l13.4,11.1c3.5,2.9,5.6,7.1,5.9,11.6l1.8,32.6l27,14.4c3.8,2,6.6,5.5,7.9,9.6l6.1,20.2
C190.1,125.8,189.7,130.2,187.6,134L187.6,134z"
    />
    <path
      d="M165.2,107.7L137.7,93c-4.8-2.6-8-7.5-8.3-13l-1.9-33.2l-12-9.9l-28.1-0.1l-13.8,12l-0.2,32.9
c0,5.2-2.6,10-6.9,12.9l-27.3,18.4l-2.3,18.1l15.7,23.3l14.9,4.5l28.7-16.8c4.7-2.7,10.5-2.8,15.4-0.3l27.5,14.8l18.4-5.6l13.4-25
L165.2,107.7z M139.6,150.7l-25.5-13.7c-6.5-3.4-14.4-3.3-20.8,0.4l-26.6,15.6L56,149.8l-13.4-20l1.7-13.6l25.2-17
c5.8-3.9,9.3-10.5,9.4-17.5l0.2-30.4l10.4-9l24.1,0.1l8.6,7.1l1.8,30.8c0.4,7.4,4.7,14,11.2,17.5l25.5,13.7l4.2,14l-11.3,21
L139.6,150.7z"
    />
  </svg>
)

export const Arrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.707"
    height="15.354"
    viewBox="0 0 10.707 15.354"
  >
    <g transform="translate(-678.146 -5981.5)">
      <line
        y2="15"
        transform="translate(683.5 5981.5)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
      <line
        x1="5"
        y2="5"
        transform="translate(683.5 5991.5)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
      <line
        x2="5"
        y2="5"
        transform="translate(678.5 5991.5)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
    </g>
  </svg>
)

export const GalleryArrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="43"
    viewBox="0 0 43 43"
    x="0px"
    y="0px"
    {...props}
  >
    <g
      id="Group_11"
      data-name="Group 11"
      transform="translate(-1270.5 -6419.5)"
    >
      <g transform="matrix(1, 0, 0, 1, 1270.5, 6419.5)">
        <circle id="Ellipse_2-2" cx="21.5" cy="21.5" r="21.5" fill="#393e3a" />
      </g>
      <path
        id="Polygon_2"
        d="M1301,6441.5l-14,10.5v-21L1301,6441.5z"
        fill="#fff"
      />
    </g>
  </svg>
)

export const CheckMark = props => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140 140"
    {...props}
  >
    <circle
      className={"path circle"}
      fill="none"
      stroke="#19AF5D"
      strokeWidth="10"
      strokeMiterlimit="10"
      cx="67.1"
      cy="67.1"
      r="62.1"
    />
    <polyline
      className={"path check"}
      fill="none"
      stroke="#19AF5D"
      strokeWidth="10"
      strokeLinecap="square"
      strokeMiterlimit="10"
      points="100.2,45.2 51.5,93.8 29.8,72.5"
    />
  </svg>
)

export const DownloadIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.93 47.07" {...props}>
    <path d="M23.8 0L0 47.08h65.93V0H23.8zm9.14 17.87l6.87 6.86 6.86-6.86h2.42l-8.08 8.07-1.2 1.24-1.21-1.22-8.08-8.07h2.42zm20.29 14.86H26.38V22.44h1.71v8.57h23.43v-8.57h1.71v10.29z" />
  </svg>
)

export const FileIcon = ({ fileType, ...props }: any) => (
  <div style={{ position: "relative" }} {...props}>
    <svg
      version="1.1"
      baseProfile="tiny"
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 84 112"
      overflow="visible"
      {...props}
    >
      <path d="M66.2,4L80,17.4V108H4V4H66.2 M67.8,0H0v112h84V15.7L67.8,0L67.8,0z M66,18h16L66,2V18z" />
    </svg>
    <span
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "100%",
        textAlign: "right",
        padding: "9px 7px",
        fontSize: "12px",
        fontWeight: "bold",
        textTransform: "uppercase",
      }}
    >
      {fileType || "pdf"}
    </span>
  </div>
)

export const NotFound = props => (
  <svg
    id="e2652d7b-d014-44e7-aeea-709bf92e6067"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="853.29744"
    height="818"
    viewBox="0 0 853.29744 818"
    {...props}
  >
    <defs>
      <linearGradient
        id="fe5e6308-1e89-4ce8-8c8d-e7ed4a72d158"
        x1="689.87057"
        y1="396.55769"
        x2="776.68053"
        y2="396.55769"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#fff" stop-opacity="0.3" />
      </linearGradient>
      <linearGradient
        id="b0adbdce-87b5-465c-b00c-1cf2f5493111"
        x1="631.27384"
        y1="535.45364"
        x2="718.08381"
        y2="535.45364"
      />
    </defs>
    <path
      d="M880.85249,505.2494C773.13547,668.24287,722.473,859,527.10189,859S173.35128,700.62046,173.35128,505.2494,349.88639,233.74549,527.10189,151.49879C1102.708-115.64322,1121.87492,140.543,880.85249,505.2494Z"
      transform="translate(-173.35128 -41)"
      fill="#515a53"
    />
    <circle cx="669.52185" cy="94.22192" r="66.1926" fill="#e6e6e6" />
    <circle cx="663.0111" cy="48.64669" r="7.59587" fill="#cbcbcb" />
    <circle cx="676.0326" cy="124.60541" r="7.59587" fill="#cbcbcb" />
    <circle cx="705.33096" cy="82.28555" r="5.42562" fill="#cbcbcb" />
    <circle cx="643.47886" cy="99.64754" r="17.36199" fill="#cbcbcb" />
    <circle cx="441.64569" cy="196.22363" r="10.85125" fill="#19AF5D" />
    <circle cx="732.45907" cy="224.43687" r="10.85125" fill="#19AF5D" />
    <circle cx="603.32925" cy="245.05424" r="6.51075" fill="#e6e6e6" />
    <circle cx="102.00171" cy="450.14278" r="6.51075" fill="#e6e6e6" />
    <circle cx="487.22092" cy="624.84783" r="6.51075" fill="#e6e6e6" />
    <polygon
      points="589.224 438.245 585.975 441.473 582.747 438.223 580.581 440.375 583.808 443.625 580.558 446.852 582.71 449.019 585.96 445.791 589.188 449.041 591.354 446.889 588.126 443.639 591.376 440.412 589.224 438.245"
      fill="#FACC14"
    />
    <polygon
      points="158.43 544.588 155.18 547.815 151.953 544.565 149.786 546.717 153.014 549.967 149.764 553.194 151.916 555.361 155.165 552.133 158.393 555.383 160.56 553.231 157.332 549.982 160.582 546.754 158.43 544.588"
      fill="#e6e6e6"
    />
    <polygon
      points="184.473 355.776 181.223 359.003 177.996 355.754 175.829 357.905 179.057 361.155 175.807 364.383 177.959 366.549 181.208 363.322 184.436 366.571 186.603 364.42 183.375 361.17 186.625 357.942 184.473 355.776"
      fill="#e6e6e6"
    />
    <polygon
      points="751.993 155.028 748.743 158.255 745.516 155.006 743.349 157.157 746.577 160.407 743.327 163.635 745.479 165.801 748.729 162.574 751.956 165.823 754.123 163.672 750.895 160.422 754.145 157.194 751.993 155.028"
      fill="#e6e6e6"
    />
    <polygon
      points="495.904 245.093 492.654 248.321 489.426 245.071 487.26 247.223 490.487 250.472 487.238 253.7 489.389 255.867 492.639 252.639 495.867 255.889 498.033 253.737 494.806 250.487 498.055 247.26 495.904 245.093"
      fill="#e6e6e6"
    />
    <path
      d="M691.79334,446.99143l32.97058-38.59855,32.97057-38.59855,18.61242-21.7895c1.02981-1.2056-.55938-3.08643-1.58915-1.88088L741.78719,384.7225l-32.97057,38.59855L690.2042,445.11056c-1.02982,1.2056.55937,3.08642,1.58914,1.88087Z"
      transform="translate(-173.35128 -41)"
      opacity="0.8"
      fill="url(#fe5e6308-1e89-4ce8-8c8d-e7ed4a72d158)"
    />
    <path
      d="M633.19662,585.88737l32.97057-38.59855,32.97057-38.59855,18.61242-21.7895c1.02981-1.2056-.55937-3.08643-1.58915-1.88087l-32.97057,38.59855L650.21989,562.217l-18.61242,21.7895c-1.02981,1.2056.55937,3.08643,1.58915,1.88087Z"
      transform="translate(-173.35128 -41)"
      opacity="0.8"
      fill="url(#b0adbdce-87b5-465c-b00c-1cf2f5493111)"
    />
    <path
      d="M293.52619,331.32164h61.8521a0,0,0,0,1,0,0v24.33858a15.811,15.811,0,0,1-15.811,15.811H309.33722a15.811,15.811,0,0,1-15.811-15.811V331.32164A0,0,0,0,1,293.52619,331.32164Z"
      fill="#1f211f"
    />
    <path
      d="M549.34694,695.68875c-14.0849,14.27061-28.19145,14.366-42.31946.46l-10.85164-95.95094L488.58,696.34927c-14.11847,11.42686-27.27574,12.96547-39.06448.42461-11.24565-83.07535-17.35815-160.2537-7.59587-219.19516l-20.61737-83.55459,46.66036-21.70249c9.39634,23.24307-5.42324,35.1313,28.08839,34.73339,33.21631-.39441,30.73557-12.88943,29.42321-34.73339l42.92319,16.50892a15.38272,15.38272,0,0,1,9.14663,18.98963l-21.68633,68.6734C564.2064,511.16046,555.63436,607.59438,549.34694,695.68875Z"
      transform="translate(-173.35128 -41)"
      fill="#e6e6e6"
    />
    <path
      d="M484.23947,779.24335s-34.64058,3.03439-39.281,1.12362h0q-.43935-.18092-.85525-.39352c-6.89625-3.52114-7.10638-13.3821-.66091-17.67309l13.66907-9.1L445.175,689.178l46.1178.54256Z"
      transform="translate(-173.35128 -41)"
      fill="#1f211f"
    />
    <path
      d="M510.28246,779.78591s34.64057,3.03439,39.281,1.12363h0q.43935-.18092.85525-.39352c6.89625-3.52114,7.10638-13.3821.66091-17.67309l-13.66907-9.1L554.23,689.72057H505.942Z"
      transform="translate(-173.35128 -41)"
      fill="#1f211f"
    />
    <path
      d="M428.89811,551.36719l6.86638,27.46551a16.55029,16.55029,0,0,1-7.20087,17.996l0,0a16.55029,16.55029,0,0,1-25.30968-12.20314L401.77,570.89943l-5.42562-130.45524a54.87781,54.87781,0,0,1,33.85878-50.69295l10.63132-4.40811Z"
      transform="translate(-173.35128 -41)"
      fill="#e6e6e6"
    />
    <path
      d="M601.43292,439.3606l-.03259.77046-4.38388,105.30046-.84636,20.34609-.16279,4.0367-1.48662,13.72678a16.55048,16.55048,0,1,1-32.51036-5.79453l6.86886-27.4645-.15193-2.04-.91147-12.76107L556.94281,384.258l10.63421,4.4056a54.9579,54.9579,0,0,1,33.8559,50.697Z"
      transform="translate(-173.35128 -41)"
      fill="#e6e6e6"
    />
    <path
      d="M500.57674,559.19089c-39.41736,0-63.732-13.67-64.07587-13.86818l1.0846-1.8799c.48428.27976,49.24918,27.63359,120.8939,3.923l.68244,2.06A185.89809,185.89809,0,0,1,500.57674,559.19089Z"
      transform="translate(-173.35128 -41)"
      fill="#1f211f"
    />
    <path
      d="M461.5451,659.23535c-8.67463-19.51846-8.71861-104.3521-8.716-107.954l2.17025.00212c-.00053.87743.04026,87.97033,8.52946,107.07022Z"
      transform="translate(-173.35128 -41)"
      fill="#1f211f"
    />
    <path
      d="M466.05727,660.11066c-7.72727,0-12.86148-1.6457-16.20852-3.533-6.09428-3.43658-7.35161-8.11936-7.40194-8.31646l2.10242-.53832-1.05121.26916,1.04962-.27446c.044.16425,1.14129,4.06286,6.46307,7.02364,5.85956,3.25855,17.65446,5.69054,40.55865-1.2356l.62733,2.077C481.23524,658.89731,472.69094,660.11066,466.05727,660.11066Z"
      transform="translate(-173.35128 -41)"
      fill="#1f211f"
    />
    <rect
      x="448.66696"
      y="382.62543"
      width="2.17072"
      height="170.16399"
      transform="translate(-195.69162 -18.43964) rotate(-2.80385)"
      fill="#1f211f"
    />
    <path
      d="M535.14707,659.23535l-1.98375-.88166c8.4892-19.09989,8.53052-106.19279,8.52946-107.07022l2.17025-.00212C543.86515,554.88325,543.82171,639.71689,535.14707,659.23535Z"
      transform="translate(-173.35128 -41)"
      fill="#1f211f"
    />
    <path
      d="M530.6349,660.11066c-6.63367,0-15.17585-1.21229-26.13942-4.52807l.62734-2.077c22.90418,6.92508,34.69643,4.49521,40.55865,1.2356,5.32177-2.96078,6.41855-6.85939,6.462-7.02364l2.10137.54362c-.05087.1971-1.30766,4.87988-7.40195,8.31646C543.49638,658.465,538.36005,660.11066,530.6349,660.11066Z"
      transform="translate(-173.35128 -41)"
      fill="#1f211f"
    />
    <rect
      x="461.85785"
      y="466.62206"
      width="170.16399"
      height="2.17072"
      transform="translate(-120.26149 950.16282) rotate(-87.20185)"
      fill="#1f211f"
    />
    <polygon
      points="355.248 367.131 339.101 391.003 304.377 391.003 292.767 367.131 297.867 367.131 306.548 386.663 335.846 386.663 349.953 367.131 355.248 367.131"
      fill="#2e312f"
    />
    <polygon points="394.443 379.067 394.725 379.631 394.443 380.152 394.443 379.067" />
    <path
      d="M603.60317,439.3606l-.03259.77046-20.58478,5.97905-12.73935-25.47877,13.03236-23.8944A54.80994,54.80994,0,0,1,603.60317,439.3606Z"
      transform="translate(-173.35128 -41)"
      fill="#2e312f"
    />
    <path
      d="M395.00956,439.3606l.03259.77046,20.58478,5.97905,12.73935-25.47877-13.03236-23.8944A54.80994,54.80994,0,0,0,395.00956,439.3606Z"
      transform="translate(-173.35128 -41)"
      fill="#2e312f"
    />
    <circle cx="324.45224" cy="291.71459" r="57.5116" fill="#e6e6e6" />
    <circle cx="324.45224" cy="299.31046" r="42.31986" fill="#1f211f" />
    <polygon
      points="424.284 504.432 423.403 524.778 395.571 509.825 394.849 507.242 393.9 494.481 424.284 504.432"
      fill="#2e312f"
    />
    <polygon
      points="226.324 504.432 227.25 524.778 256.503 509.825 257.263 507.242 258.26 494.481 226.324 504.432"
      fill="#2e312f"
    />
    <polygon
      points="339.678 392.089 303.698 392.089 283.777 351.139 282.753 336.41 284.918 336.26 285.913 350.568 305.057 389.918 338.525 389.918 363.075 353.628 368.301 337.605 370.366 338.277 364.958 354.718 339.678 392.089"
      fill="#1f211f"
    />
  </svg>
)
