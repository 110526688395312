/** @jsx jsx */
import { Column } from "components/layout"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Flex, jsx } from "theme-ui"

const LanguagePicker = () => {
  const { language, languages, changeLanguage } = useI18next()

  const handleChangeLanguage = (lng) => (e) => {
    e.preventDefault()
    changeLanguage(lng)
  }

  return (
    <Flex
      sx={{
        minHeight: "100%",
        height: "100%",
        alignItems: "center",
        mr: [0, 0, 0, 0, 5],
        ml: [0, 0, 0, 5, 0],
        position: ["relative", "relative", "relative", "relative", "absolute"],
        right: 0,
        top: 0,
      }}
    >
      {languages.map(lng => (
        <button
          key={lng}
          className={"active"}
          onClick={handleChangeLanguage(lng)}
          sx={{
            position: "relative",
            height: "100%",
            minHeight: ["76px", "76px", "76px", "auto"],
            variant: "buttons.language",
            cursor: "pointer",
          }}
        >
          <Column
            key={lng}
            sx={{ p: "0 !important", display: "flex", alignItems: "center" }}
          >
            <img
              alt={lng}
              src={`/${lng}.svg`}
              width={32}
              height={32}
              sx={{
                width: ["24px", "24px", "32px", "17px"],
                position: "relative",
                m: 0,
                mx: 2,
              }}
            />
          </Column>

          {lng === language && (
            <span
              sx={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: ["24px", "24px", "32px", "17px"],
                height: "5px",
                backgroundColor: "primary",
              }}
            />
          )}
        </button>
      ))}
    </Flex>
  )
}

export default LanguagePicker
